import React from 'react';
import { navigate } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getLabList, removeLabItemId } from '../../actions/lab';
import { createLabListSelector } from '../../reducers/lab';
import { createLoadingSelector } from '../../reducers/api';
import { getUrlParams } from '../../helpers/location';
import { debounce } from '../../helpers/utility';
import Helmet from 'react-helmet';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import Input from '@material-ui/core/Input';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';
import LabAddToCartDialog from '../../components/LabAddToCartDialog/index';
import LabItemList from '../../components/LabItemList';
import SelectLocation from '../../components/SelectLocation';
import Layout from '../../components/Layout';
import LoaderView from '../../components/LoaderView';

const useStyles = makeStyles((theme) => ({
  tab: {
    position: 'absolute',
    zIndex: 1,
    width: '100%'
  },
  list: {
    width: '100%',
    '& > div + div': {
      marginTop: 10
    }
  },
  orderNowButton: {
    width: 160,
    color: 'white',
    fontSize: '1.1em',
    backgroundColor: theme.palette.primary.main,
  },
  orderNow: {
    position: 'absolute',
    bottom: theme.spacing(2),
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  paper: {
    padding: theme.spacing(1, 1),
    opacity: 0.9,
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  locationHead: {
    position: 'relative',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  locationA: {
    paddingLeft: 26,
    height: 20,
    // whiteSpace: 'nowrap',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
  },
}));

function getTitle(package_type) {
  if (package_type === 'checkup') {
    return 'โปรแกรมตรวจสุขภาพ';
  } else {
    return 'ตรวจแล็บ';
  }
}

export default ({ location }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = getUrlParams(location.search);
  const [showProduct, setShowProduct] = React.useState();
  const [filter, setFilter] = React.useState('');
  const isLoadingMenus = useSelector(createLoadingSelector(['lab/list/get', 'lab/orders/get']));
  const labList = useSelector(createLabListSelector(filter));
  const currentSelected = useSelector(state => state.lab.currentSelected);
  const orderInfo = useSelector(state => state.orderInfo);
  const _address = orderInfo.address;
  const type = query.package_type || 'checkup'; // lab, checkup

  React.useEffect(() => {
    if (_address){
      const { latitude, longitude } = _address
      dispatch(getLabList(type, latitude, longitude));
    }
  }, [_address]);

  const handleAddMenu = (item) => {
    window.logEvent('select_content', { content_type: 'lab', item_id: item.id + '', item_name: item.name });
    setShowProduct(item.id);
  }

  const handleRemoveMenu = (item) => {
    dispatch(removeLabItemId(item.id));
  }

  const handleFilter = (e) => {
    const val = e.target.value;
    if (val && val.length > 1) {
      window.logEvent('search', { search_term: val });
    }
    setFilter(val);
  }

  const onSubmit = () => {
    navigate('/labStore/personalInfo');
  }

  return (
    <Layout hideAppBar>
      <Helmet>
        <title>{getTitle(query.package_type)} - MorOnline</title>
      </Helmet>
      <LabAddToCartDialog
        productId={showProduct}
        open={!!showProduct}
        onClose={() => { setShowProduct(null) }}
      />
      <Box p={2} pb={8}>
        <Paper className={classes.paper} square>
          <div className={classes.locationHead}>
            <LocationOnIcon style={{ position: 'absolute', top: -2 }} />
            <div className={classes.locationA}>
              <SelectLocation force />
            </div>
          </div>
        </Paper>
        <Paper className={classes.paper} square>
          <Box mr={1}>ค้นหา</Box>
          <Input onChange={debounce(handleFilter, 500)} placeholder="eg. Cholesterol" fullWidth/>
        </Paper>
        <LoaderView
          loading={isLoadingMenus && labList.length === 0}
          empty={!isLoadingMenus && labList.length === 0}
        >
          <LabItemList
            limitNumOfSelected={type === 'checkup' ? 1 : undefined}
            items={labList}
            selected={currentSelected}
            addMenu={handleAddMenu}
            removeMenu={handleRemoveMenu}
            hasNext={false}
          />
        </LoaderView>
      </Box>
      {
        !!currentSelected.length && (
          <div className={classes.orderNow}>
            <Fab
              className={classes.orderNowButton}
              variant="extended"
              onClick={onSubmit}
            >
              ยืนยัน ({currentSelected.length} รายการ)
            </Fab>
          </div>
        )
      }
    </Layout>
  )
}
